import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import moment, { Moment } from 'moment';
import momentTimezone from 'moment-timezone';
import i18n from 'utils/i18n';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import ChooseLocationDrawer from 'containers/ChooseLocationDrawer';

import UploadImage from 'components/common/UploadImage';
import Input from 'components/common/Input';
import ScheduleTime from 'components/ScheduleTime';
import {
  BeepIcon,
  CalendarWithStarIcon,
  CupIcon,
  EventsIcon,
  FlagIcon,
  LocationIcon,
  RoadIcon,
  StarIcon,
  WorldIcon,
  ArrowRight,
} from 'public/assets/icons';
import { Selector, InputDescriptionField } from 'components';
import { composeValidators, required } from 'utils/validators';
import EventOwnerShipDrawer from 'containers/EventOwnerShipDrawer';
import { IStepOne } from './ICreateEventPostForm';

import {
  EventTypeItemWrap,
  EventTypesHeaderText,
  EventTypesHeaderWrap,
  EventTypesList,
  EventTypesWrap,
  EventTypeText,
  LocationText,
  SectionWrapper,
  Select,
  SelectIconWrap,
  SelectWrapper,
  SeparateSettingsHeader,
  TimeZoneText,
  AttendanceBlockLeft,
  AttendanceBlockRight,
  AttendanceBlockText,
  AttendanceWrapperLeft,
  EventOwnerWrapper,
  EventOwnerBlock,
  EventSectionWrapper,
  DesktopLoginHeaderRight,
} from './CreateEventPostForm.styles';
import PostCreatorSelect from './PostCreatorSelect';
import { getDateInOriginalTimezone } from 'utils/date';
import { mapTimezoneOffsetToMinutes } from 'utils/time';

const EventTypes = () => [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:meetup'),
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
    icon: (color) => <StarIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:drive'),
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
    icon: (color) => <RoadIcon color={color} />,
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:race'),
    value: process.env._EVENT_RACES,
    pinType: 'races',
    icon: (color) => <CupIcon color={color} />,
  },
  {
    id: 3,
    name: i18n.t('buttonGroupLabels:trackDay'),
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
    icon: (color) => <FlagIcon color={color} />,
  },
  {
    id: 4,
    name: i18n.t('buttonGroupLabels:carShow'),
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
    icon: (color) => <BeepIcon color={color} />,
  },
  {
    id: 5,
    name: i18n.t('settings:other'),
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
    icon: (color) => <CalendarWithStarIcon color={color} />,
  },
];

const EVENT_TYPE = [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:meetup'),
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
    icon: (color) => <StarIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:drive'),
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
    icon: (color) => <RoadIcon color={color} />,
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:race'),
    value: process.env._EVENT_RACES,
    pinType: 'races',
    icon: (color) => <CupIcon color={color} />,
  },
  {
    id: 3,
    name: i18n.t('buttonGroupLabels:trackDay'),
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
    icon: (color) => <FlagIcon color={color} />,
  },
  {
    id: 4,
    name: i18n.t('buttonGroupLabels:carShow'),
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
    icon: (color) => <BeepIcon color={color} />,
  },
  {
    id: 5,
    name: i18n.t('settings:other'),
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
    icon: (color) => <CalendarWithStarIcon color={color} />,
  },
];

const EventsTypesList = ({ id, onClick }: any) => (
  <>
    {EVENT_TYPE.map((event) => (
      <EventTypeItemWrap
        selected={event.id === id}
        key={event.id}
        onClick={() => onClick(event.pinType)}
      >
        {event.icon(event.id === id ? '#FBFBFB' : '#A9A9B5')}
        <EventTypeText selected={event.id === id}>{event.name}</EventTypeText>
      </EventTypeItemWrap>
    ))}
  </>
);

const StepOne = ({ form, data, initialValues, isDuplicating }: IStepOne) => {
  const [images, setImages] = useState<Array<any>>([]);

  const [eventStart, setEventStart] = useState<Moment>(
    initialValues?.startDate ? moment(initialValues?.startDate) : moment()
  );

  const [eventEnd, setEventEnd] = useState<Moment>(
    initialValues?.endDate
      ? moment(initialValues?.endDate)
      : moment().add(1, 'hour')
  );

  const [openChooseLocationDrawer, setOpenChooseLocationDrawer] =
    useState<boolean>(false);
  const [timezone, setTimeZone] = useState<any>(
    initialValues?.timezone && /^[+-]\d{2}:\d{2}$/.test(initialValues?.timezone)
      ? initialValues?.timezone
      : initialValues?.timezone
      ? momentTimezone.tz(initialValues?.timezone).format('Z')
      : format(new Date(), 'xxx')
  );
  const [interests, setInterests] = useState<Array<string>>([]);

  const { t } = useTranslation();

  const [isOwnershipDrawerOpen, setIsOwnershipDrawerOpen] =
    useState<boolean>(false);

  const [eventOwnership, setEventOwnership] = useState({
    title: null,
    text: null,
    isRealOwner: null,
  });

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timezone
    );
    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);
    if (isAbbrValid) {
      return `(GMT${offset}) ${tzAbbrMoment}`;
    }
    return label;
  }, [timezone]);

  useEffect(() => {
    if (initialValues?.coverPhoto) {
      setImages([...images, initialValues?.coverPhoto]);
    }
    form.change('startDate', eventStart.valueOf());
    form.change('endDate', eventEnd.valueOf());
  }, []);

  // useEffect(() => {
  //   setEventEnd(new Date(eventStart.getTime() + 3600000));
  //   form.change('endDate', eventEnd.getTime());
  // }, [eventStart]);

  const updateTimezone = (offset: any) => {
    const updatedStartMoment = moment(eventStart).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    const updatedEndMoment = moment(eventEnd).utcOffset(
      mapTimezoneOffsetToMinutes(offset)
    );
    setEventStart(updatedStartMoment);
    setEventEnd(updatedEndMoment);
    form.change('startDate', updatedStartMoment.valueOf());
    form.change('endDate', updatedEndMoment.valueOf());
  };

  useEffect(() => {
    updateTimezone(timezone)
  }, []);

  useEffect(() => {
    if (eventStart.isAfter(eventEnd)) {
      const newEndDate = moment(eventStart).add(1, 'hour');
      setEventEnd(newEndDate);
      form.change('endDate', newEndDate.valueOf());
    }
  }, [eventStart, eventEnd, form]);

  const handleClubSelect = (clubId: string) => {
    form.change('group', clubId);
  };

  return (
    <>
      <SectionWrapper>
        {/* <Field name="coverPhoto">
          {({ input }) => {
            form.change('coverPhoto', images[images.length - 1]);
            return (
              <UploadMultipleImages
                images={images}
                setImages={setImages}
                isOnePhoto
                label="Add a cover photo for your event"
                {...input}
              />
            );
          }}
        </Field> */}
        <SeparateSettingsHeader mt={10} mb={15}>
          {t('event:postingAs')}
        </SeparateSettingsHeader>
        <SeparateSettingsHeader mb={20}>
          <DesktopLoginHeaderRight>
            <PostCreatorSelect
              onClubSelect={handleClubSelect}
              initialValues={initialValues}
              isDuplicating={isDuplicating}
            />
          </DesktopLoginHeaderRight>
        </SeparateSettingsHeader>
        <UploadImage
          name="coverPhoto"
          isOnePhoto
          label="Add a cover photo for your event"
          isAspect
        />

        <SeparateSettingsHeader mt={20} mb={20}>
          {t('event:mainInformation')}
        </SeparateSettingsHeader>
        <Input
          id="eventName"
          name="eventName"
          type="text"
          label={t('event:eventName')}
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        />
      </SectionWrapper>

      <SectionWrapper>
        <InputDescriptionField
          // placeholder={t('event:addEventDetails')}
          label={t('event:eventDetails')}
          name="eventDetails"
          // onChange={onChange}
          showCharactersNumber
          maxLength={3000}
          height={122}
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        />
      </SectionWrapper>

      <SectionWrapper pt={16.5} pb={16.5}>
        <Field name="startDate">
          {({ input }) => {
            return (
              <ScheduleTime
                selectedDate={getDateInOriginalTimezone(eventStart)}
                headerText={t('event:eventStart')}
                onScheduleTimeChange={(v) => {
                  const updatedMoment = moment(v).utcOffset(
                    mapTimezoneOffsetToMinutes(timezone),
                    true
                  );
                  setEventStart(updatedMoment);
                  input.onChange(updatedMoment.valueOf());
                }}
                minDate={new Date()}
              />
            );
          }}
        </Field>

        <Field name="endDate">
          {({ input }) => {
            return (
              <ScheduleTime
                selectedDate={getDateInOriginalTimezone(eventEnd)}
                headerText={t('event:eventEnd')}
                onScheduleTimeChange={(v) => {
                  const updatedMoment = moment(v).utcOffset(
                    mapTimezoneOffsetToMinutes(timezone),
                    true
                  );
                  setEventEnd(updatedMoment);
                  input.onChange(updatedMoment.valueOf());
                }}
                minDate={eventStart || new Date()}
              />
            );
          }}
        </Field>

        <SeparateSettingsHeader mb={4} mt={16}>
          {t('timePicker:timezone')}
        </SeparateSettingsHeader>

        <Field name="timezone">
          {() => {
            form.change('timezone', timezone);
            return (
              <SelectWrapper>
                <SelectIconWrap>
                  <WorldIcon />
                </SelectIconWrap>
                <TimeZoneText>{getTimezone}</TimeZoneText>
                <Select
                  onChange={(e: any) => {
                    setTimeZone(e.target.value);
                    updateTimezone(e.target.value);
                  }}
                  value={timezone}
                >
                  {minimalTimezoneSet.map((el, index) => (
                    <option value={el.offset} key={index}>
                      {el.label}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            );
          }}
        </Field>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="location">
          {({ input }) => {
            return (
              <>
                <Selector
                  title={t('event:location')}
                  isSelectedItemsExist={input?.value?.address}
                  onClick={() => setOpenChooseLocationDrawer(true)}
                  leftIcon={<LocationIcon />}
                />
                {input?.value && (
                  <LocationText>{input?.value?.address}</LocationText>
                )}
                <ChooseLocationDrawer
                  open={openChooseLocationDrawer}
                  handleSubmit={(e) => input.onChange(e)}
                  setOpenChooseLocationDrawer={setOpenChooseLocationDrawer}
                  location={input?.value}
                />
              </>
            );
          }}
        </Field>
      </SectionWrapper>

      <SectionWrapper pt={22.5}>
        <Field
          name="interests"
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        >
          {({ input }) => {
            const getIn = EVENT_TYPE.filter(
              (v) => v?.pinType === input?.value
            )[0];

            return (
              <EventTypesWrap>
                <EventTypesHeaderWrap>
                  <EventsIcon />
                  <EventTypesHeaderText>
                    {t('event:eventType')}
                  </EventTypesHeaderText>
                </EventTypesHeaderWrap>
                <EventTypesList>
                  <EventsTypesList
                    id={getIn?.id}
                    onClick={(v) => input.onChange(v)}
                  />
                </EventTypesList>
              </EventTypesWrap>
            );
          }}
        </Field>

        <EventSectionWrapper>
          <Field name="isRealOwner">
            {() => (
              <EventOwnerWrapper onClick={() => setIsOwnershipDrawerOpen(true)}>
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:eventOwnership:heading')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>
                {eventOwnership?.text && (
                  <EventOwnerBlock>
                    <AttendanceBlockLeft>
                      <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                        {eventOwnership?.title}
                      </SeparateSettingsHeader>
                      <AttendanceBlockText>
                        {eventOwnership?.text}
                      </AttendanceBlockText>
                    </AttendanceBlockLeft>
                  </EventOwnerBlock>
                )}
              </EventOwnerWrapper>
            )}
          </Field>
        </EventSectionWrapper>
      </SectionWrapper>

      <EventOwnerShipDrawer
        form={form}
        open={isOwnershipDrawerOpen}
        setOpen={setIsOwnershipDrawerOpen}
        setEventOwnerShip={setEventOwnership}
      />
    </>
  );
};

export default StepOne;
