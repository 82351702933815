import styled from 'styled-components';
interface IProps {
}

export const StatsContainer = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 5px;
  border-radius: 8px;
  margin-top: 16px;
  cursor: ${props => props.onClick? 'pointer' : 'default'};
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const IconWrapper = styled.div`
  background-color: #3b82f6;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: white;
    width: 24px;
    height: 24px;
  }
`;

export const CountSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Count = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

export const Label = styled.span`
  color: #22c55e;
  font-weight: 400;
  font-size: 8px;
`;

export const Amount = styled.span`
  font-size: 28px;
  font-weight: bold;
  color: #ff950a;
`;

export const VerticalLine = styled.div`
  width: 2px;
  height: 40px; 
  background-color: #3b82f6; 
  margin: 0 8px; 
`;
export const Text = styled.span`
  color: black;
  font-weight: 600;
  font-size: 12px;

`;
export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  flex-direction: column;
`;