import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  tagType: null,
  maxSize: null,
  tagsData: {
    events: null,
    users: null,
    groups: null,
    brands: null,
    collaborators: null,
    interests: null,
    models: null,
    types: null,
    admins: null,
    location: null,
  },

  attendedEventId: null,
  inviteEventId: null,
  attendenceViewEventId: null,
  eventData:null,

  pendingNotifications: null,
  carBrandId: null,
  vehicleId: null,

  chatData: null,
  selectedUsers: null,
  chatId: null,
  groupForEditData: null,
  eventForEditData: null,
  postForEditData: null,

  defaultTagId: null,

  sidebarHeaderStartText: null,

  involvedUsersInfo: {
    involvedUsersType: '',
    id: null,
    isAdmin: null,
  },

  likesInfo: null,

  scrollToTop: false,
  commentsType: '',
  commentsPostId: null,
  isSidebarOpen: false,
  isOpenSideBarEditProfile: false,
  isOpenSideBarCreateLinkPost: false,
  isOpenSideBarCreatePhotoPost: false,
  isOpenSideBarTag: false,
  isOpenSideBarAddVehicle: false,
  isOpenSideBarAddVehicleToGroup: false,
  isOpenSidebarCreateEvent: false,
  isOpenSideBarTagWithAdding: false,
  isOpenSidebarComments: false,
  isOpenSidebarNotifications: false,
  isOpenSidebarPendingApprovals: false,
  isOpenSidebarInviteToGroupe: false,
  isOpenSidebarAttendEvent: false,
  isOpenSidebarInviteEvent: false,
  isOpenSidebarEventAttendanceView: false,
  isOpenSidebarChatsList: false,
  isOpenSidebarChatConversation: false,
  isOpenSidebarNewChat: false,
  isOpenSidebarNewMultipleChat: false,
  attendingEventStatus: AsyncStatus.NONE,
  cancelAttendEventSucces: AsyncStatus.NONE,
  isOpenSidebarCreateGroup: false,
  isOpenSidebarChooseLocation: false,
  isOpenSidebarSuggestions: false,
  isOpenSidebarLikes: false,
  isOpenSidebarInvolvedUsers: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // EDIT PROFILE
    case actionTypes.OPEN_SIDEBAR_EDIT_PROFILE:
      return {
        ...initialState,
        isOpenSideBarEditProfile: true,
        isSidebarOpen: true,
      };
    case actionTypes.CLOSE_SIDEBAR_EDIT_PROFILE:
      return {
        ...initialState,
      };

    // LINK POST
    case actionTypes.OPEN_SIDEBAR_CREATE_LINK_POST:
      return {
        ...initialState,
        postForEditData: action.data.postForEditData || null,
        defaultTagId: action.data.defaultTagId || null,
        isOpenSideBarCreateLinkPost: true,
        isSidebarOpen: true,
      };
    case actionTypes.CLOSE_SIDEBAR_CREATE_LINK_POST:
      return {
        ...initialState,
      };

    // PHOTO POST
    case actionTypes.OPEN_SIDEBAR_CREATE_PHOTO_POST:
      return {
        ...initialState,
        postForEditData: action.data.postForEditData || null,
        defaultTagId: action.data.defaultTagId || null,
        isOpenSideBarCreatePhotoPost: true,
        isSidebarOpen: true,
      };
    case actionTypes.CLOSE_SIDEBAR_CREATE_PHOTO_POST:
      return {
        ...initialState,
      };

    // ADD VEHICLE
    case actionTypes.OPEN_SIDEBAR_ADD_VEHICLE:
      return {
        ...initialState,
        ...{
          isOpenSideBarAddVehicle: true,
          vehicleId: action.data || null,
          isSidebarOpen: true,
        },
      };
    case actionTypes.CLOSE_SIDEBAR_ADD_VEHICLE:
      return {
        ...initialState,
      };

    // ADD VEHICLE TO GROUP
    case actionTypes.OPEN_SIDEBAR_ADD_VEHICLE_TO_GROUP:
      return {
        ...initialState,
        ...{
          isOpenSideBarAddVehicleToGroup: true,
          vehicleId: action.data || null,
          isSidebarOpen: true,
        },
      };
    case actionTypes.CLOSE_SIDEBAR_ADD_VEHICLE_TO_GROUP:
      return {
        ...initialState,
      };

    // CREATE EVENT
    case actionTypes.OPEN_SIDEBAR_CREATE_EVENT:
      return {
        ...initialState,
        isOpenSidebarCreateEvent: true,
        isSidebarOpen: true,
        eventForEditData: action.data || null,
      };
    case actionTypes.CLOSE_SIDEBAR_CREATE_EVENT:
      return { ...initialState };

    // COMMENTS
    case actionTypes.OPEN_SIDEBAR_COMMENTS:
      return {
        ...initialState,
        isOpenSidebarComments: true,
        commentsPostId: action.data.commentsPostId,
        commentsType: action.data.commentsType,
        isSidebarOpen: true,
      };
    case actionTypes.CLOSE_SIDEBAR_COMMENTS:
      return { ...initialState };

    // NOTIFICATIONS
    case actionTypes.OPEN_SIDEBAR_NOTIFICATIONS:
      return {
        ...initialState,
        isOpenSidebarNotifications: true,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_NOTIFICATIONS:
      return {
        ...initialState,
      };

    // TAG
    case actionTypes.OPEN_SIDEBAR_TAG:
      return {
        ...state,
        isOpenSideBarTag: true,
        tagType: action.data.tagType,
        isSidebarOpen: true,
        sidebarHeaderStartText: action.data.sidebarHeaderStartText || null,
      };
    case actionTypes.CLOSE_SIDEBAR_TAG:
      return {
        ...state,
        isOpenSideBarTag: false,
        tagType: null,
      };

    // TAG WITH ADDING
    case actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING:
      return {
        ...state,
        tagType: action.data.tagType,
        isOpenSideBarTagWithAdding: true,
        maxSize: action.data.maxSize,
        carBrandId: action.data.brandId || null,
        isSidebarOpen: true,
        sidebarHeaderStartText: action.data.sidebarHeaderStartText || null,
      };
    case actionTypes.CLOSE_SIDEBAR_TAG_WITH_ADDING:
      return {
        ...state,
        isOpenSideBarTagWithAdding: false,
        tagType: null,
        maxSize: null,
        carBrandId: null,
      };

    // ADD TAGS
    case actionTypes.ADD_TAGS:
      return {
        ...state,
        tagsData: { ...state.tagsData, [action.data.type]: action.data.tags },
        isOpenSideBarTag: false,
        isOpenSideBarTagWithAdding: false,
        isSidebarOpen: true,
      };
    case actionTypes.REMOVE_TAG: {
      const selectedTags = state.tagsData[action.data.type];
      const newTagsData = selectedTags.filter(
        (tag) => tag._id !== action.data.id
      );

      if (action.data.type === 'brands') {
        return {
          ...state,
          tagsData: {
            ...state.tagsData,
            [action.data.type]: newTagsData,
            models: null,
          },
        };
      }

      return {
        ...state,
        tagsData: { ...state.tagsData, [action.data.type]: newTagsData },
      };
    }

    // SCROLL TO TOP
    case actionTypes.SCROLL_TO_TOP:
      return { ...state, scrollToTop: true };

    // PENDING APPROVALS
    case actionTypes.OPEN_SIDEBAR_PENDING_APPROVALS:
      return {
        ...state,
        isOpenSidebarPendingApprovals: true,
        isSidebarOpen: true,
        pendingNotifications: action.data,
      };

    case actionTypes.CLOSE_SIDEBAR_PENDING_APPROVALS:
      return {
        ...state,
        isOpenSidebarPendingApprovals: false,
        pendingNotifications: null,
      };

    // OPEN SIDEBAR INVITE USER TO GROUP
    case actionTypes.OPEN_SIDEBAR_ADD_USER_GROUP:
      return {
        ...state,
        isOpenSidebarInviteToGroupe: true,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_ADD_USER_GROUP:
      return {
        ...initialState,
      };

    // OPEN SIDEBAR _ATTEND_EVENT
    case actionTypes.OPEN_SIDEBAR_ATTEND_EVENT:
      return {
        ...state,
        isOpenSidebarAttendEvent: true,
        attendedEventId: action.data,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_ATTEND_EVENT:
      return {
        ...initialState,
      };

    // INVITE USERS TO EVENT
    case actionTypes.OPEN_SIDEBAR_INVITE_EVENT:
      return {
        ...state,
        isOpenSidebarInviteEvent: true,
        inviteEventId: action.data,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_INVITE_EVENT:
      return {
        ...initialState,
      };

    // ATTENDANCE VIEW EVENT
    case actionTypes.OPEN_SIDEBAR_EVENT_ATTENDANCE_VIEW:
      return {
        ...state,
        isOpenSidebarEventAttendanceView: true,
        attendenceViewEventId: action.id,
        eventData: action.data,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_EVENT_ATTENDANCE_VIEW:
      return {
        ...initialState,
      };

    // CHATS LIST
    case actionTypes.OPEN_SIDEBAR_CHATS_LIST:
      return {
        ...initialState,
        isSidebarOpen: true,
        isOpenSidebarChatsList: true,
      };

    case actionTypes.CLOSE_SIDEBAR_CHATS_LIST:
      return { ...initialState };

    // CHAT CONVERSATION
    case actionTypes.OPEN_SIDEBAR_CHAT_CONVERSATION:
      return {
        ...state,
        chatData: action.data,
        isOpenSidebarChatConversation: true,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_CHAT_CONVERSATION:
      return {
        ...state,
        chatData: null,
        isOpenSidebarChatConversation: false,
        isSidebarOpen: state.isOpenSidebarChatsList,
      };

    // NEW CHAT
    case actionTypes.OPEN_SIDEBAR_NEW_CHAT:
      return {
        ...state,
        isOpenSidebarNewChat: true,
      };

    case actionTypes.CLOSE_SIDEBAR_NEW_CHAT:
      return {
        ...state,
        isOpenSidebarNewChat: false,
      };

    // NEW MULTIPLE CHAT
    case actionTypes.OPEN_SIDEBAR_NEW_MULTIPLE_CHAT:
      return {
        ...state,
        selectedUsers: action.data.selectedUsers || null,
        chatId: action.data.chatId || null,
        isOpenSidebarNewMultipleChat: true,
      };

    case actionTypes.CLOSE_SIDEBAR_NEW_MULTIPLE_CHAT:
      return {
        ...state,
        selectedUsers: null,
        chatId: null,
        isOpenSidebarNewMultipleChat: false,
      };

    // CLEAR SIDEBAR STORE
    case actionTypes.CLEAR_SIDEBAR_STORE:
      return { ...initialState };

    // CREATE GROUP
    case actionTypes.OPEN_SIDEBAR_CREATE_GROUP:
      return {
        ...initialState,
        isOpenSidebarCreateGroup: true,
        isSidebarOpen: true,
        groupForEditData: action.data || null,
      };

    case actionTypes.CLOSE_SIDEBAR_CREATE_GROUP:
      return {
        ...initialState,
      };

    // CHOOSE LOCATION
    case actionTypes.OPEN_SIDEBAR_CHOOSE_LOCATION:
      return { ...state, isOpenSidebarChooseLocation: true };

    case actionTypes.CLOSE_SIDEBAR_CHOOSE_LOCATION:
      return { ...state, isOpenSidebarChooseLocation: false };

    // SUGGESTIONS
    case actionTypes.OPEN_SIDEBAR_SUGGESTIONS:
      return {
        ...initialState,
        isOpenSidebarSuggestions: true,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_SUGGESTIONS:
      return { ...initialState };

    // LIKES
    case actionTypes.OPEN_SIDEBAR_LIKES:
      return {
        ...initialState,
        isOpenSidebarLikes: true,
        isSidebarOpen: true,
        likesInfo: action.data,
      };

    case actionTypes.CLOSE_SIDEBAR_LIKES:
      return { ...initialState };

    // INVOLVED USERS
    case actionTypes.OPEN_SIDEBAR_INVOLVED_USERS:
      return {
        ...initialState,
        involvedUsersInfo: {
          isAdmin: action.data.isAdmin || null,
          involvedUserType: action.data.involvedUserType,
          id: action.data.id || null,
        },
        isOpenSidebarInvolvedUsers: true,
        isSidebarOpen: true,
      };

    case actionTypes.CLOSE_SIDEBAR_INVOLVED_USERS:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}

export default reducer;
