import { useState } from 'react';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import {
  loadConnectAndInitialize,
  StripeConnectInstance,
} from '@stripe/connect-js';

export const useStripeConnect = (group: string) => {
  const { action } = fetchApi();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [stripeConnectInstance, setStripeConnectInstance] =
    useState<StripeConnectInstance | null>(null);
  const [stripeAccountId, setStripeAccountId] = useState<string | null>(null);

  const fetchAccountDetails = async () => {
    const { clientSecret, stripeAccountId } = await action(
      API_NAME.INITIALIZE_STRIPE_ACCOUNT,
      {
        body: {
          group,
        },
      }
    );
    setStripeAccountId(stripeAccountId);
    return { clientSecret, stripeAccountId };
  };

  const initializeStripeConnect = async () => {
    setError(null);

    try {
      const stripeConnectInstance = loadConnectAndInitialize({
        fetchClientSecret: async () => {
          const accountDetails = await fetchAccountDetails();
          return accountDetails.clientSecret;
        },
        publishableKey: process.env._STRIPE_PUBLISHABLE_KEY,
      });
      setStripeConnectInstance(stripeConnectInstance);
    } catch (error) {
      console.log('Error initializing Stripe Connect:', error);
      setError('Failed to initialize Stripe Connect');
    } finally {
      setIsLoading(false);
    }
  };

  return {
    initializeStripeConnect,
    stripeConnectInstance,
    isLoading,
    error,
    stripeAccountId,
  };
};
