import React from 'react';

import { CheckMarkIcon } from 'public/assets/icons';
import { IPill } from './IPill';
import { Wrapper, Text, IconWrapper } from './Pill.style';

const Pill = React.memo(
  ({
    text,
    onClick,
    isSelected,
    margin,
    iconLeft,
    iconRight,
    fontSize,
    backgroundColor,
    color,
    highlight,
  }: IPill) => (
    <Wrapper
      onClick={iconRight ? null : onClick}
      margin={margin}
      isSelected={isSelected}
      iconLeft={typeof iconLeft !== 'undefined'}
      iconRight={typeof iconRight !== 'undefined'}
      backgroundColor={backgroundColor}
      highlight={highlight}
    >
      {isSelected && <CheckMarkIcon />}
      {iconLeft}
      <Text
        isSelected={isSelected}
        iconLeft={typeof iconLeft !== 'undefined'}
        fontSize={fontSize}
        color={color}
        highlight={highlight}
      >
        {text}
      </Text>
      {iconRight && <IconWrapper onClick={onClick}>{iconRight}</IconWrapper>}
    </Wrapper>
  )
);

export default Pill;
