import React, { useState } from 'react';
import {
  ConnectComponentsProvider,
  ConnectAccountOnboarding,
  CommonComponentProps,
} from '@stripe/react-connect-js';
import {
  CollectionOptions,
  LoadError,
  StepChange,
  StripeConnectInstance,
} from '@stripe/connect-js';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import { useTranslation } from 'react-i18next';

type ConnectAccountOnboardingProps = {
  onExit: () => void;
  onStepChange?: ((stepChange: StepChange) => void) | undefined;
  recipientTermsOfServiceUrl?: string | undefined;
  fullTermsOfServiceUrl?: string | undefined;
  privacyPolicyUrl?: string | undefined;
  skipTermsOfServiceCollection?: boolean | undefined;
  collectionOptions?: CollectionOptions | undefined;
} & CommonComponentProps;

type StripeConnectOnboardingProps = {
  connectInstance: StripeConnectInstance | null;
} & ConnectAccountOnboardingProps;

const StripeConnectOnboarding: React.FC<StripeConnectOnboardingProps> = ({
  connectInstance,
  onExit,
}) => {
  if (!connectInstance) return null;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const onLoaderStart = () => {
    setLoading(true);
  };

  const onLoadError = (error: LoadError) => {
    setLoading(false);
    setError(error.error.message || t('auth:timeoutError'));
  };

  return (
    <ConnectComponentsProvider connectInstance={connectInstance}>
      <ConnectAccountOnboarding
        onExit={onExit}
        onLoadError={onLoadError}
        onLoaderStart={onLoaderStart}
        collectionOptions={{
          fields: 'eventually_due',
          futureRequirements: 'include',
        }}
      />
    </ConnectComponentsProvider>
  );
};

export default StripeConnectOnboarding;
