import { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import Input from 'components/common/Input';
import {
  ArrowRight,
  DiscussionIcon,
  MailIcon,
  PhoneIcon,
  WorldIcon,
} from 'public/assets/icons';
import CreateEventSwitcherSettings from 'containers/CreateEventSwitcherSettings';
import AttendanceManagementDrawer from 'containers/AttendanceManagementDrawer';
import { composeValidators, required } from 'utils/validators';
import EventOwnerShipDrawer from 'containers/EventOwnerShipDrawer';
import { IStepTwo } from './ICreateEventPostForm';
import EventBrands from './EventBrands';
import MyCollaborators from './MyCollaborators';
import Routes from './Routes';

import {
  AttendanceBlock,
  AttendanceBlockLeft,
  AttendanceBlockRight,
  AttendanceBlockText,
  AttendanceWrapper,
  AttendanceWrapperLeft,
  InputsContainers,
  SectionWrapper,
  SelectorWrapper,
  SeparateSettingsHeader,
  EventOwnerWrapper,
  EventOwnerBlock,
  EventSectionWrapper,
  RegistrationWrapper,
  RegistrationBlock,
} from './CreateEventPostForm.styles';
import CreateRegistrationFormDrawer from 'containers/CreateRegistrationFormDrawer';
import CreatePaymentFormDrawer from 'containers/CreatePaymentFormDrawer';
import moment from 'moment';

const StepTwo = ({
  form,
  initialValues,
  eventStartDate,
  eventEndDate,
  eventTimeZone,
  isEventOwner,
}: IStepTwo) => {
  const { t } = useTranslation();
  const [isAttendanceDrawerOpen, setIsAttendanceDrawerOpen] = useState(false);
  const [isRegisterFormDrawerOpen, setIsRegisterFormDrawerOpen] =
    useState(false);
  const [isPaymentFormDrawerOpen, setIsPaymentFormDrawerOpen] = useState(false);

  const [attendanceManagement, setAttendanceManagement] = useState({
    title: null,
    text: null,
    attenderManagement: null,
  });

  const regFormInitValues = useMemo(() => {
    return {
      ...initialValues,
      regStartDate: moment().valueOf(),
      regEndDate: eventStartDate,
      regTimezone: eventTimeZone,
    };
  }, []);

  return (
    <>
      <SelectorWrapper>
        <Routes />
      </SelectorWrapper>
      <InputsContainers>
        <Input
          id="attendeeCap"
          name="attendeeCap"
          type="text"
          label={t('event:attendeeCap')}
          prefix={<DiscussionIcon height="18" width="18" color="#8D8D8D" />}
        />
      </InputsContainers>

      {isEventOwner && (
        <>
          <Field name="registrationForm">
            {() => (
              <RegistrationWrapper
                onClick={() => setIsRegisterFormDrawerOpen(true)}
              >
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:registrationManagement')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>

                <RegistrationBlock>
                  <AttendanceBlockLeft>
                    <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                      {t('event:registrationForm')}
                    </SeparateSettingsHeader>
                    <AttendanceBlockText>
                      {t('event:registrationFormDescription')}
                    </AttendanceBlockText>
                  </AttendanceBlockLeft>
                </RegistrationBlock>
              </RegistrationWrapper>
            )}
          </Field>

          <Field name="paymentForm">
            {() => (
              <RegistrationWrapper
                onClick={() => setIsPaymentFormDrawerOpen(true)}
              >
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:paymentManagement')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>

                <RegistrationBlock>
                  <AttendanceBlockLeft>
                    <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                      {t('event:paymentForm')}
                    </SeparateSettingsHeader>
                    <AttendanceBlockText>
                      {t('event:paymentFormDescription')}
                    </AttendanceBlockText>
                  </AttendanceBlockLeft>
                </RegistrationBlock>
              </RegistrationWrapper>
            )}
          </Field>
        </>
      )}
      <SectionWrapper pt={5} pb={17.5}>
        <SelectorWrapper>
          <EventBrands />
          <MyCollaborators />
        </SelectorWrapper>

        <InputsContainers>
          <Input
            id="contactPhone"
            name="contactPhone"
            type="text"
            label={t('event:contactPhone')}
            prefix={<PhoneIcon />}
          />
          <Input
            id="contactEmail"
            name="contactEmail"
            type="text"
            label={t('event:contactEmail')}
            prefix={<MailIcon />}
          />
          <Input
            id="contactWebsite"
            name="contactWebsite"
            type="text"
            label={t('event:eventWebpageOrRouteLink')}
            prefix={<WorldIcon />}
          />
        </InputsContainers>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="eventSettings">
          {({ input }) => {
            return (
              <CreateEventSwitcherSettings
                eventSettings={input?.value}
                setEventSettings={input?.onChange}
                initialValues={initialValues}
                form={form}
              />
            );
          }}
        </Field>
      </SectionWrapper>

      <SectionWrapper>
        <Field name="attenderManagement">
          {() => {
            if (attendanceManagement?.attenderManagement) {
              form.change(
                'attenderManagement',
                attendanceManagement?.attenderManagement
              );
            }
            return (
              <AttendanceWrapper
                onClick={() => setIsAttendanceDrawerOpen(true)}
              >
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:attendanceManagement:heading')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>
                {attendanceManagement?.text && (
                  <AttendanceBlock>
                    <AttendanceBlockLeft>
                      <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                        {attendanceManagement?.title &&
                          attendanceManagement?.title}
                      </SeparateSettingsHeader>
                      <AttendanceBlockText>
                        {attendanceManagement?.text &&
                          attendanceManagement?.text}
                      </AttendanceBlockText>
                    </AttendanceBlockLeft>
                  </AttendanceBlock>
                )}
              </AttendanceWrapper>
            );
          }}
        </Field>
      </SectionWrapper>

      <AttendanceManagementDrawer
        open={isAttendanceDrawerOpen}
        setOpen={setIsAttendanceDrawerOpen}
        setAttendanceManagement={setAttendanceManagement}
      />

      <CreateRegistrationFormDrawer
        form={form}
        open={isRegisterFormDrawerOpen}
        initialValues={regFormInitValues}
        setOpen={setIsRegisterFormDrawerOpen}
      />
      
      <CreatePaymentFormDrawer
        open={isPaymentFormDrawerOpen}
        setOpen={setIsPaymentFormDrawerOpen}
        form={form}
        initialValues={{
          ...initialValues,
          startDate: initialValues.startDate || eventStartDate,
          endDate: initialValues.endDate || eventEndDate,
          timeZone: initialValues.timeZone || eventTimeZone,
        }}
      />
    </>
  );
};

export default StepTwo;
