import React from 'react';
import { Card, Typography, List, Button, Space, Row, Col } from 'antd';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { Title, Paragraph } = Typography;

interface OnboardingFailureProps {
  missingRequirements: string[];
  handleRetryOnboarding: () => void;
}

export const OnboardingFailure: React.FC<OnboardingFailureProps> = ({
  missingRequirements,
  handleRetryOnboarding,
}) => {
  const { t } = useTranslation();

  return (
    <Card className="max-w-md mx-auto mt-8">
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row align="middle" justify="center" gutter={16} style={{ padding: 0 }}>
          <Col>
            <ExclamationCircleOutlined
              style={{ fontSize: '24px', color: '#faad14', margin: 0 }}
            />
          </Col>
          <Col>
            <Title level={3} style={{ color: '#262626', margin: 0 }}>
              {t('event:onboardingFailure:finishOnboarding')}
            </Title>
          </Col>
        </Row>
        <Paragraph
          className="text-center"
          style={{ fontSize: '16px', color: '#595959', marginBottom: 0 }}
        >
          {t('event:onboardingFailure:completeOnboardingSteps')}
        </Paragraph>
        <List
          size="small"
          dataSource={missingRequirements}
          renderItem={(item) => (
            <List.Item>
              <Typography.Text style={{ fontSize: '14px', color: '#8c8c8c' }}>
                • {item}
              </Typography.Text>
            </List.Item>
          )}
        />
        <Button
          block
          type="primary"
          icon={<ReloadOutlined />}
          size="large"
          onClick={handleRetryOnboarding}
        >
          {t('event:onboardingFailure:retryOnboarding')}
        </Button>
      </Space>
    </Card>
  );
};
