import styled from 'styled-components';

export const ThreeDotsWrap = styled.div`
  width: 50px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transform: rotate(90deg);
`;

export const TrashIconWrap = styled.svg`
  width: 24px;
  margin-left: 10px;
  height: 24px;
  min-width: 24px;
  cursor: pointer;
`;

export const FieldDropdownWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;
  width: 24px; 

  & > div {
    & > button {
      position: relative;
      z-index: 1;
    }
    & > div {
      top: 0;
      right: 0;
      & > ul {
        top: 32px;
        right: 2px;
      }
    }
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const FieldContent = styled.div`
  flex-grow: 1;
  margin-right: 15px;
`;
