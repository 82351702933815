import React from 'react';
import { FormField, FormFieldType, IFormField } from '../FormField/FormField';
import { SectionHeader } from 'forms/CreateEventPostForm/CreateEventPostForm.styles';
import Text from 'components/common/Text';
import Dropdown from 'components/Dropdown';
import { ThreeDotsIcon, TrashIcon } from 'public/assets/icons';
import {
  FieldDropdownWrapper,
  FieldWrapper,
  FieldContent,
  ThreeDotsWrap,
  TrashIconWrap,
} from './FieldCollection.styles';
import { useTranslation } from 'react-i18next';

export interface IFieldCollection {
  name?: string;
  fields: IFormField[];
  mustEditBeforeSubmit?: boolean;
}

export interface FieldCollectionProps {
  collection: IFieldCollection;
  readOnly?: boolean;
  disabled?: boolean;
  onRemoveField?: (fieldIndex: number) => void;
  onEditField?: (fieldIndex: number) => void;
  onRemoveCollection?: () => void;
  isLastCollection?: boolean;
  currency?: string;
}

export const FieldCollection = ({
  collection,
  disabled,
  readOnly,
  onRemoveField,
  onEditField,
  onRemoveCollection,
  currency,
  isLastCollection,
}: FieldCollectionProps) => {
  const { t } = useTranslation();

  const getTranslatedLabel = (label: string) =>
    t(`event:${label}`) === `event:${label}` ? label : t(`event:${label}`);

  const getLabel = (
    label: string,
    isSingleItem: boolean,
    currency?: string
  ) => {
    const labelText = currency
      ? `${getTranslatedLabel(label)} (${currency})`
      : getTranslatedLabel(label);
    if (isSingleItem) {
      return <SectionHeader>{labelText}</SectionHeader>;
    }
    return <Text>{labelText}</Text>;
  };

  return (
    <div style={{ marginTop: 10 }}>
      {collection.name && (
        <SectionHeader>
          <>
            {t(`event:${collection.name}`)}
            {!readOnly && (
              <TrashIconWrap onClick={() => onRemoveCollection()}>
                <TrashIcon />
              </TrashIconWrap>
            )}
          </>
        </SectionHeader>
      )}
      {collection.fields.map((field, i) => {
        const isSingleItem =
          !collection?.name && collection?.fields?.length === 1;

        const isLastItem =
          isLastCollection && i === collection.fields.length - 1;

        return (
          <FieldWrapper key={i}>
            <FieldContent>
              {readOnly ? (
                <>
                  {field.type === FormFieldType.TextBox &&
                    getLabel(field?.label, isSingleItem, currency)}

                  {field.type === FormFieldType.DatePicker &&
                    getLabel(field?.label, isSingleItem, currency)}

                  {field.type === FormFieldType.Checkbox &&
                    getLabel(field?.label, isSingleItem, currency)}

                  {field.type === FormFieldType.MultipleOptions && (
                    <>
                      {getLabel(field?.label, isSingleItem, currency)}

                      {field?.options?.map((option) => (
                        <Text key={option.value}>{option.label}</Text>
                      ))}
                    </>
                  )}
                  {field.type === FormFieldType.Paragraph &&
                    getLabel(field?.label, isSingleItem, currency)}
                </>
              ) : (
                <FormField
                  key={i}
                  field={field}
                  disabled={disabled}
                  currency={currency}
                />
              )}
            </FieldContent>
            {!readOnly && (
              <Dropdown
                top={isLastItem ? -270 : -160}
                right={-5}
                listItems={[
                  {
                    id: 0,
                    text: t('event:editQuestion'),
                    onClick: () => onEditField(i),
                  },
                  {
                    id: 1,
                    text: t('event:deleteQuestion'),
                    onClick: () => onRemoveField(i),
                  },
                ]}
              >
                <FieldDropdownWrapper>
                  <ThreeDotsWrap>
                    <ThreeDotsIcon colorStroke="#C4C4C4" />
                  </ThreeDotsWrap>
                </FieldDropdownWrapper>
              </Dropdown>
            )}
          </FieldWrapper>
        );
      })}
    </div>
  );
};
