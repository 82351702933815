import {
  UserNameEvent,
  EventUserItemWrap,
  ImageWrap,
  EmptyEventImg,
  EventNameWrap,
} from '../AttendanceView.style';
import Image from 'components/common/Image';
import { EmptyGroupIcon } from 'public/assets/icons';
import { StatusDropdown } from './StatusDropdown';

export const EventUserItem = ({
  el,
  loading,
  eventId,
  title,
  adminId,
  reloadData,
}: any) => {
  const attendeeId = el && el?._id;

  return (
    <EventUserItemWrap>
      {el?.profilePhoto ? (
        <ImageWrap>
          <Image
            loader={() => el.profilePhoto}
            src={el?.profilePhoto?.trim() || el?.coverPhoto?.trim()}
            alt="Picture of user"
            width={48}
            height={48}
            unoptimized
            priority
          />
        </ImageWrap>
      ) : (
        <ImageWrap>
          <EmptyEventImg>
            <EmptyGroupIcon />
          </EmptyEventImg>
        </ImageWrap>
      )}
      <EventNameWrap>
        <UserNameEvent>{el?.username}</UserNameEvent>
        <span>{el?.fullName}</span>
      </EventNameWrap>

      {attendeeId !== adminId && (
        <StatusDropdown
          eventId={eventId}
          attendeeId={el?._id}
          attendeeStatus={title}
          reloadData={reloadData}
          loading={loading}
        />
      )}
    </EventUserItemWrap>
  );
};
