import styled from 'styled-components';

import { style } from 'utils/constants/style';
interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fs?: number;
  fw?: number;
  mr?: number;
  padding?: string;
}

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: 13px;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
  text-align: center;
`;
export const DefaultBadge = styled.p`
  font-size: 16px;
  color: ${style.mainWhite.white};
  margin: 0;
  padding-left: 8px;
  background-color: ${style.mainYellow.orangeGradientTop};
  text-align: center;
  border-radius: 30px;
  padding-right: 8px;
`;

export const LabelText = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${style.mainBlue.dodgerBlueColor};
`;

export const Heading = styled.label`
  color: ${style.mainBlack.charcoalColor};
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const AttendanceViewButton = styled.div`
  display: flex;
  width: 90%;
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    height: 40px;
    margin-bottom: 10px;
  }
`;

export const DefaultAccountWrapper = styled.div`
  border: 2px solid #3b82f6;
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
  margin-top: 30px;
`;

export const AccountSelectionWrapper = styled.div<IProps>`
  border: 1px solid ${style.mainBlack.charcoalColor};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 8px;
  cursor: pointer;
`;

export const AccountTypeContainer = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const AccountNumber = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  margin-bottom: 8px;
  margin-left: 20px;
  gap: 5px;
`;

export const AccountSelectionText = styled.div`
  font-size: 16px;
  color: ${style.mainWhite.white};
  margin: 0;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
`;

export const TermServiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 116px;
`;

export const AcceptTermsText = styled.div`
  font-size: 14px;
  color: ${style.mainBlack.charcoalColor};
  margin-left: 20px;
  display: flex;
  gap: 1px;
`;

export const LinkText = styled.div`
  color: ${style.mainBlue.dodgerBlueColor};
  cursor: pointer;
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
