import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InputField, LoadingComponent, RectangleButton } from 'components';

import { ArrowBack, SearchIcon } from 'public/assets/icons';
import { closeSidebarInviteEvent } from 'store/sideBarControler/actions';
import {
  MainWrapper,
  SeparateSectionsHeader,
  WaitlistHeaderWrapper,
  ButtonWrap,
  AttendanceViewButton,
} from './AttendanceView.style';
import { SidebarWrapper } from '..';
import { getEventParticipants } from 'services';
import AttendanceStats from './AttendenceStats/AttendenceStats';
import { EventUserItem } from './EventUserItem/EventUserItem';
import { style } from 'utils/constants/style';
import { WaitlistModal } from './WaitlistModal/WaitlistModal';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import Button from 'components/common/Button';
import { ThemeContext } from 'theme';

interface EventParticipant {
  _id: string;
  username: string;
  fullName: string;
  profilePhoto?: string;
  coverPhoto?: string;
}

interface AttendanceViewProps {
  eventId?: string;
  eventData?: any;
}

interface EventParticipants {
  confirmed: EventParticipant[];
  waitlisted: EventParticipant[];
  requestingApproval: EventParticipant[];
  pendingRegistrationForm: EventParticipant[];
  pendingPaymentForm: EventParticipant[];
  pendingPayment: EventParticipant[];
  readyToConfirm: EventParticipant[];
}

const AttendanceView = ({ eventId, eventData }: AttendanceViewProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { action } = fetchApi();
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const context: any = useContext(ThemeContext);
  const [eventParticipants, setEventParticipants] = useState<EventParticipants>(
    {
      confirmed: [],
      waitlisted: [],
      requestingApproval: [],
      pendingRegistrationForm: [],
      pendingPaymentForm: [],
      pendingPayment: [],
      readyToConfirm: [],
    }
  );
  const currentAttendeeCap = eventData && eventData?.attendeeCap;

  const fetchEventParticipants = useCallback(async () => {
    if (!eventId) return;

    setLoading(true);
    try {
      const response = await getEventParticipants(eventId);
      const transformedData = {
        confirmed: response?.confirmed,
        waitlisted: response?.waitlisted,
        requestingApproval: response?.requestingApproval,
        pendingRegistrationForm: response?.pendingRegistrationForm,
        pendingPaymentForm: response?.pendingPaymentForm,
        pendingPayment: response?.pendingPayment,
        readyToConfirm: response?.readyToConfirm,
      };
      setEventParticipants(transformedData);
    } catch (error) {
      console.error('Failed to fetch attendance data:', error);
    } finally {
      setLoading(false);
    }
  }, [eventId]);

  useEffect(() => {
    fetchEventParticipants();
  }, [fetchEventParticipants]);

  const handleWaitlist = () => {
    setIsModalOpen(true);
  };

  const handleSendReminder = () => {
    action(API_NAME.REMIND_ALL_USER, {
      params: { eventId },
    });
  };

  const handleSendReport = () => {
    action(API_NAME.GET_SUBMISSION_REPORT, {
      params: { eventId },
    });
  };

  const filterUsers = (users: EventParticipant[]) => {
    if (!searchQuery) return users;
    return users.filter(
      (user) =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.fullName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  const renderSection = (title: string, users: EventParticipant[]) => {
    const filteredUsers = filterUsers(users);
    if (filteredUsers?.length === 0) return null;

    if (title === 'waitlist') {
      return (
        <>
          <WaitlistHeaderWrapper>
            <SeparateSectionsHeader mt={20} mb={20}>
              {t(`event:${title}`) + ` - ${filteredUsers?.length}`}
            </SeparateSectionsHeader>

            <ButtonWrap>
              <RectangleButton
                color={style.mainWhite.white}
                text={t('common:open')}
                backgroundColor={style.mainOrange.darkOrange}
                onClick={handleWaitlist}
                loading={loading}
              />
            </ButtonWrap>
          </WaitlistHeaderWrapper>
          {filteredUsers?.map((user) => (
            <EventUserItem
              key={user._id}
              el={user}
              loading={loading}
              eventId={eventId}
              title={title}
              adminId={context?.user?._id}
              reloadData={fetchEventParticipants}
            />
          ))}
        </>
      );
    }

    return (
      <>
        <SeparateSectionsHeader mt={20} mb={20}>
          {t(`event:${title}`) + ` - ${filteredUsers?.length}`}
        </SeparateSectionsHeader>
        {filteredUsers?.map((user) => (
          <EventUserItem
            key={user._id}
            el={user}
            loading={loading}
            eventId={eventId}
            title={title}
            adminId={context?.user?._id}
            reloadData={fetchEventParticipants}
          />
        ))}
      </>
    );
  };

  const updateAttendeeCap = async (val: any) => {
    try {
      await action(API_NAME.UPDATE_EVENT_CAP, {
        params: { eventId },
        body: {
          newAttendeeCap: val,
        },
      });
      await fetchEventParticipants();
    } catch (error) {
      console.error('Failed to update attendee cap:', error);
    }
  };

  return (
    <SidebarWrapper
      headerText={t('event:attendanceView')}
      headerIcon={<ArrowBack />}
      onIconClick={() => {
        dispatch(closeSidebarInviteEvent());
      }}
      contentPadding="0 0 20px 0"
    >
      <MainWrapper>
        <InputField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          iconLeft={<SearchIcon colorStroke="#C2C2C2" />}
          crossIcon
          maxLength={50}
          placeholder={t('event:search')}
        />
        {loading ? (
          <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
        ) : (
          <>
            <AttendanceStats
              confirmedCount={eventParticipants?.confirmed?.length}
              totalAmount={0}
              handleSendReport={handleSendReport}
            />
            {renderSection('confirmed', eventParticipants?.confirmed)}
            {renderSection('readyToConfirm', eventParticipants?.readyToConfirm)}
            {renderSection('waitlist', eventParticipants?.waitlisted)}
            {renderSection(
              'requestingApproval',
              eventParticipants?.requestingApproval
            )}
            {renderSection(
              'pendingRegistrationForm',
              eventParticipants?.pendingRegistrationForm
            )}
            {renderSection(
              'pendingPaymentForm',
              eventParticipants?.pendingPaymentForm
            )}
            {renderSection('pendingPayment', eventParticipants?.pendingPayment)}
          </>
        )}
      </MainWrapper>
      <AttendanceViewButton>
        <Button variant="primary" onClick={handleSendReminder} width="full">
          {t('event:sendReminder')}
        </Button>
      </AttendanceViewButton>

      <WaitlistModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        onSubmit={(e) => updateAttendeeCap(e)}
        initialAttendeeCap={currentAttendeeCap}
      />
    </SidebarWrapper>
  );
};

export default AttendanceView;
