import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const FormWrapper = styled.form`
  width: 100%;
`;

export const ContentWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 31, 41, 0.3);
  z-index: 110;
`;

export const ModalWrap = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 30px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${style.mainWhite.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  position: relative;
  box-sizing: border-box;
`;

export const HeaderText = styled.h2`
  font-size: 24px;
  line-height: 130%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  text-align: center;
`;

export const CloseIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export const FormFieldWrap = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
