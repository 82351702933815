import { style } from 'utils/constants/style';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { ButtonWrap } from '../AttendanceView.style';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import Dropdown from 'components/Dropdown';
import { RectangleButton } from 'components/Buttons';
import { IDropdown } from 'components/Dropdown/IDropdown';

interface StatusDropdownProps {
  attendeeStatus: string;
  attendeeId: string;
  eventId: string;
  reloadData?: () => void;
  loading?: boolean;
}

export const StatusDropdown: React.FC<StatusDropdownProps> = ({
  attendeeStatus,
  attendeeId,
  eventId,
  reloadData,
  loading = false,
}) => {
  const { t } = useTranslation();
  const { action } = fetchApi();
  const router = useRouter();

  const refreshAfterAction = useCallback(() => {
    reloadData && reloadData();
    router.replace(router.asPath);
  }, [reloadData, router]);

  const createHandler = useCallback(
    (
        apiName: (typeof API_NAME)[keyof typeof API_NAME],
        shouldRefresh: boolean = true
      ) =>
      () => {
        try {
          action(apiName, {
            params: { eventId, attendeeId },
          });

          if (shouldRefresh) {
            refreshAfterAction();
          }
        } catch (error) {
          console.error(`Error with action ${apiName}:`, error);
        }
      },
    [action, eventId, attendeeId, refreshAfterAction]
  );

  const getDropdownItems = useMemo(() => {
    const baseItems = [
      {
        text: t('event:removeUser'),
        onClick: createHandler(API_NAME.REMOVE_USER),
      },
    ];

    const statusItemsMap: Record<string, IDropdown['listItems']> = {
      confirmed: [
        {
          text: t('event:refundAttendee'),
          onClick: createHandler(API_NAME.REFUND_ATTENDEE, false),
        },
      ],
      waitlist: [
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
        {
          text: t('event:refundAttendee'),
          onClick: createHandler(API_NAME.REFUND_ATTENDEE, false),
        },
      ],
      readyToConfirm: [
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
        {
          text: t('event:refundAttendee'),
          onClick: createHandler(API_NAME.REFUND_ATTENDEE, false),
        },
      ],
      pendingRegistration: [
        {
          text: t('event:sendReminder'),
          onClick: createHandler(API_NAME.REMIND_USER),
        },
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
      ],
      pendingRegistrationForm: [
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
      ],
      pendingPayment: [
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
      ],
      pendingPaymentForm: [
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
      ],
      requestingApproval: [
        {
          text: t('event:accept'),
          onClick: createHandler(API_NAME.ACCEPT_USER),
        },
        {
          text: t('event:reject'),
          onClick: createHandler(API_NAME.REJECT_USER),
        },
        {
          text: t('event:confirmAttendee'),
          onClick: createHandler(API_NAME.CONFIRM_USER),
        },
        {
          text: t('event:refundAttendee'),
          onClick: createHandler(API_NAME.REFUND_ATTENDEE, false),
        },
      ],
    };

    const specificItems = statusItemsMap[attendeeStatus] || [];
    return [...specificItems, ...baseItems];
  }, [attendeeStatus, t, createHandler]);

  return (
    <Dropdown top={-148} right={1} listItems={getDropdownItems}>
      <ButtonWrap>
        <RectangleButton
          loading={loading}
          text={t('event:manage')}
          onClick={() => {}}
          color={style.mainWhite.white}
          backgroundColor={style.mainBlue.blue}
        />
      </ButtonWrap>
    </Dropdown>
  );
};
