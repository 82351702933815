/* eslint-disable react/display-name */
import React, { useState } from 'react';

import { CrossIcon, EyeWithLineIcon, EyeIcon } from 'public/assets/icons';
import { IInputField } from './IInputField';
import {
  InputWrapper,
  Label,
  InputField,
  CrossIconWrapper,
  LeftIconWrapper,
  CharactersNumber,
  TextAreaInput,
} from './InputField.style';

const Input = React.forwardRef(
  (
    {
      label,
      value,
      onChange,
      type,
      onCrossClick,
      margin,
      crossIcon,
      iconLeft,
      height,
      showCharactersNumber,
      maxLength,
      placeholder,
      onBlur,
      backgroundColor,
      onKeyPress,
      pl,
      onClick,
      cursor,
      readOnly = false,
      autoComplete = 'off',
      disabled = false,
      defaultValue,
    }: IInputField,
    ref: React.MutableRefObject<any>
  ) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const inputType =
      type === 'password'
        ? showPassword
          ? 'text'
          : 'password'
        : type || 'text';

    const renderRightIcon = () => {
      if (type === 'password') {
        return (
          <CrossIconWrapper
            onClick={() => setShowPassword(!showPassword)}
            isLabelExists={!!label && !!label.length}
          >
            {showPassword ? <EyeWithLineIcon /> : <EyeIcon />}
          </CrossIconWrapper>
        );
      }
      if (crossIcon && !!value?.length && type !== 'date') {
        return (
          <CrossIconWrapper
            onClick={onCrossClick}
            isLabelExists={!!label && !!label.length}
          >
            <CrossIcon />
          </CrossIconWrapper>
        );
      }
    };

    const handleKeyPress = (e) => {
      if (onKeyPress) {
        onKeyPress(e);
      }
    };

    return (
      <InputWrapper margin={margin}>
        {label && <Label>{label}</Label>}
        {iconLeft && <LeftIconWrapper>{iconLeft}</LeftIconWrapper>}
        {type === 'textarea' ? (
          <TextAreaInput
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            maxLength={maxLength}
            crossIcon={crossIcon}
            disabled={disabled}
          />
        ) : (
          <InputField
            height={height}
            type={inputType}
            value={value}
            onChange={onChange}
            iconLeft={typeof iconLeft !== 'undefined'}
            maxLength={maxLength}
            placeholder={placeholder}
            crossIcon={crossIcon}
            onBlur={(e) => {
              if (onBlur) {
                onBlur(e);
              }
            }}
            backgroundColor={backgroundColor}
            focus={type === 'comment'}
            onKeyPress={handleKeyPress}
            pl={pl}
            onClick={onClick}
            cursor={cursor}
            ref={ref}
            readOnly={readOnly}
            autoComplete={autoComplete}
            disabled={disabled}
            defaultValue={defaultValue}
          />
        )}
        {!disabled && renderRightIcon()}
        {showCharactersNumber && type !== 'date' && (
          <CharactersNumber>{`${value.length}/${maxLength}`}</CharactersNumber>
        )}
      </InputWrapper>
    );
  }
);

export default Input;
