import React, { useState } from 'react';
import Button from 'components/common/Button';
import {
  ContentWrap,
  ModalWrap,
  HeaderText,
  CloseIconWrap,
  FormFieldWrap,
  ButtonWrapper,
  FormWrapper,
} from './WaitlistModal.style';
import { IWaitlistModal } from './IWaitlistModal';
import Input from 'components/common/Input';
import { CrossIcon } from 'public/assets/icons';
import {  Form as FinalForm } from 'react-final-form';
import { composeValidators, required } from 'utils/validators';


import { useTranslation } from 'react-i18next';

export const WaitlistModal = ({
  open,
  onClose,
  onSubmit,
  initialAttendeeCap = '',
}: IWaitlistModal) => {
  const { t } = useTranslation();
    const handleSubmit = (values: any) => {
      const newAttendeeCap = values.label;
      onSubmit(newAttendeeCap);
      onClose();
    };

  return (
    open && (
      <ContentWrap onClick={onClose}>
        <ModalWrap onClick={(e) => e.stopPropagation()}>
          <HeaderText>{t('event:attendeeCapLimit')}</HeaderText>

          <FinalForm
            onSubmit={(e: any) => handleSubmit(e)}
            initialValues={{ label: initialAttendeeCap }}
            render={(formRenderProps: any) => {
              const { handleSubmit } = formRenderProps;

              return (
                <FormWrapper onSubmit={handleSubmit}>
                  <FormFieldWrap>
                    <Input
                      id="field-name"
                      name="label"
                      type="text"
                      label={t('event:attendeeCap')}
                      validate={composeValidators(
                        required('This field is required!')
                      )}
                    />
                  </FormFieldWrap>
                  <ButtonWrapper>
                    <Button
                      variant="primary"
                      htmlType="submit"
                      onClick={handleSubmit}
                    >
                      {t('event:update')}
                    </Button>
                  </ButtonWrapper>
                </FormWrapper>
              );
            }}
          />

          <CloseIconWrap onClick={onClose}>
            <CrossIcon color="#8D8D8D" />
          </CloseIconWrap>
        </ModalWrap>
      </ContentWrap>
    )
  );
};
