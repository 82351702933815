import Heading from 'components/common/Typography/Heading';
import styled from 'styled-components';

import { style } from 'utils/constants/style';
interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fs?: number;
  fw?: number;
  mr?: number;
  padding?: string;
}

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const ValidationErrorWrapper = styled.div<any>`
  margin: 6px 0 0;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: ${(props) => props.theme.colors.red};
  letter-spacing: 0.02em;
`;

export const SectionHeader = styled.h3`
  font-size: 16px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: 13px;
`;

export const QuickPillsWrap = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-height: 130px;

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }
`;
export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
export const SelectWrapper = styled.div`
  position: relative;
  height: 44px;
  width: 100%;
  max-width: 260px;
`;
export const SelectIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 8px;
`;
export const TimeZoneText = styled.div`
  height: 44px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  color: ${(props) => props.theme.colors.black};
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
`;
export const Select = styled.select`
  height: 44px;
  width: 100%;
  max-width: 260px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: transparent;
  color: transparent;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
`;
export const Text = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
`;

export const Label = styled.label`
  color: ${style.mainBlack.charcoalColor};
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;
`;
